import React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon
} from 'react-share';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="d-flex">
                            <p>Made in Canada</p>
                        </div>                       
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-6">
                        <div className="row">
                            <div className="col">
                                <a className="footer-nav">Home</a>
                                <br />
                                <a className="footer-nav">About</a>
                                <br />
                                <a className="footer-nav">Services</a>
                                <br />
                                <a className="footer-nav">Roadmap</a>
                            </div>
                            <div className="col">
                                <a className="footer-nav">Products</a>
                                <br />
                                <a className="footer-nav">Clients</a>
                                <br />
                                <a className="footer-nav">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
                        <div className="d-flex justify-content-center">
                            <LinkedinShareButton
                                url={"https://www.commandrobotics.ai/"}
                                quote={"Innovating to improve lives"}
                                hashtag="#robotics"
                            >
                                <LinkedinIcon className="mx-3" size={36} />
                            </LinkedinShareButton>
                            <FacebookShareButton
                                url={"https://www.commandrobotics.ai/"}
                                quote={"Innovating to improve lives"}
                                hashtag="#robotics"
                            >
                                <FacebookIcon className="mx-3" size={36} />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={"https://www.commandrobotics.ai/"}
                                quote={"Innovating to improve lives"}
                                hashtag="#robotics"
                            >
                                <TwitterIcon className="mx-3" size={36} />
                            </TwitterShareButton>
                            <RedditShareButton
                                url={"https://www.commandrobotics.ai/"}
                                quote={"Innovating to improve lives"}
                                hashtag="#robotics"
                            >
                                <RedditIcon className="mx-3" size={36} />
                            </RedditShareButton>
                        </div>
                        <p className="pt-3 text-center">
                            Copyright&copy;
                            {new Date().getFullYear()}&nbsp;Command Robotics | All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;

{/* <div className="col-lg-4 col-md-6 col-sm-6">
    <div className="d-flex">
        <p>1230 Rue St-Laurent, Montreal, QC, T1D 4C1</p>
    </div>
    <div className="d-flex">
        <a href="tel:xxx-xxx-xxxx">+1-xxx-xxx-xxxx</a>
    </div>
    <div className="d-flex">
        <p>michael.muldoon.home@gmail.com</p>
    </div>
</div> */}