import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Particles from 'react-particles-js';
import Navbar from './components/Navbar';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Roadmap from './components/Roadmap';
import Products from './components/Products';
import Customers from './components/Customers';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
      
      <Navbar />
      <Header />
      <About />
      <Products />
      <Customers />
      <Roadmap />      
      <Contact />
      <Footer />
    </>
  );
}

export default App;

//<Services />