import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-scroll';

const Header = () => {
    return (
        <div id="home" className="header-wraper">
            <div className="main-info">
                <h1>advancing robotics through collaborative innovation</h1>
                <Typed 
                    className="typed-text"
                    strings={["www.Command-Central.com", "Task Management", "Powerful", "Adaptable Logistics", "Robot-as-a-Service", "For Robots connected to the Internet"]}
                    typeSpeed={40}
                    backSpeed={60}
                    loop
                />
                <Link smooth={true} to="contact" offset={0} className="btn-main-offer" href="#">Contact us</Link>
                
            </div>
        </div>
    );

}

export default Header