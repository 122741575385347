import React from 'react';
import product_001 from '../images/product_001.jpg';
import product_002 from '../images/product_002.jpg';
import product_003 from '../images/product_003.jpg';
import product_004 from '../images/product_004.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import 'react-popupbox/dist/react-popupbox.css';

const Products = () => {

    //Product 001
    const openPopupboxProduct001 = () => {
        const content = (
            <>
                <img className="products-image-popupbox" src={product_001} alt="Command-Central" />
                <p>
                    Command-Central is released in the beta stage.  It is free to try and experiment with.  You can contact us for help and there is some documentation at the github page below.
                </p>
                <b>MVP: </b>
                <br />
                <b>- </b><a className="hyper-link" onClick={() => window.open("https://www.command-central.com", "_blank")}>Command-Central.com</a>
                <br />
                <b>- </b> <a className="hyper-link" onClick={() => window.open("https://github.com/mukmalone/AdaptiveGoalManagement/blob/master/README.md", "_blank")}>Command-Central introduction</a>
                <br />
                <b>- </b> <a className="hyper-link" onClick={() => window.open("https://github.com/mukmalone/AdaptiveGoalManagement/blob/master/documentation/API_Documentation.md", "_blank")}>API Documentation</a>

            </>
        );
        PopupboxManager.open({ content })
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Command-Central",
                },
            },
        });
    }

    const popupboxConfigProduct001 = {
        titleBar: {
            enable: true,
            text: "Command-Central"
        },
        fadeIn: true,
        fadeInSpeed: 500
    }

    //Product 002
    const openPopupboxProduct002 = () => {
        const content = (
            <>
                <img className="products-image-popupbox" src={product_002} alt="Open Source Robotics" />
                <p>
                    We are big fans of ROS (<a className="hyper-link" onClick={() => window.open("https://www.ros.org/", "_blank")}>Robot Operating System</a>),
                    the <a className="hyper-link" onClick={() => window.open("https://stellar.org/", "_blank")}>Stellar Blockchain</a> and the communities around these two great projects.
                </p>
                <p>
                    During testing and validation of our project we deveoped two robotic projects and connected them to Command-Central.
                </p>

                <b>GitHub:</b> <a className="hyper-link" onClick={() => window.open("https://github.com/mukmalone/hello_robot", "_blank")}>Home service robot</a>
                <br />
                <b>GitHub:</b> <a className="hyper-link" onClick={() => window.open("https://github.com/mukmalone/AdaptiveGoalManagement", "_blank")}>MIR industrial robot simulation</a>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Open Source Robotics",
                },
            },
        });
    }

    const popupboxConfigProduct002 = {
        titleBar: {
            enable: true,
            text: "Open Source Robotics"
        },
        fadeIn: true,
        fadeInSpeed: 500
    }

    //Product 003
    const openPopupboxProduct003 = () => {
        const content = (
            <>
                <img className="products-image-popupbox" src={product_003} alt="Product 003" />
                <p>
                    Product 003 Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit
                    optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?
                </p>

                <b>GitHub:</b> <a className="hyper-link" onClick={() => window.open("https://github.com/mukmalone/hello_robot", "_blank")}>https://github.com/mukmalone/hello_robot</a>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Product 003",
                },
            },
        });
    }

    const popupboxConfigProduct003 = {
        titleBar: {
            enable: true,
            text: "Product 003"
        },
        fadeIn: true,
        fadeInSpeed: 500
    }

    //Product 004
    const openPopupboxProduct004 = () => {
        const content = (
            <>
                <img className="products-image-popupbox" src={product_004} alt="Product 004" />
                <p>
                    Product 004 Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit
                    optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?
                </p>

                <b>GitHub:</b> <a className="hyper-link" onClick={() => window.open("https://github.com/mukmalone/hello_robot", "_blank")}>https://github.com/mukmalone/hello_robot</a>
            </>
        );
        PopupboxManager.open({ content });
        PopupboxManager.update({
            content,
            config: {
                titleBar: {
                    text: "Product 004",
                },
            },
        });
    }

    const popupboxConfigProduct004 = {
        titleBar: {
            enable: true,
            text: "Product 004"
        },
        fadeIn: true,
        fadeInSpeed: 500
    }

    return (
        <div id="products" className="products-wrapper">
            <div className="container">
                <h1 className="text-uppercase text-center py-5">products</h1>
                {/* - */}
                <div className="image-box-wrapper row justify-content-center">
                    <div className="products-image-box" onClick={openPopupboxProduct001}>
                        <img className="products-image" src={product_001} alt="Product 001"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="products-icon" icon={faSearchPlus} />
                    </div>
                    {/* - */}
                    <div className="products-image-box" onClick={openPopupboxProduct002}>
                        <img className="products-image" src={product_002} alt="Product 002"></img>
                        <div className="overflow"></div>
                        <FontAwesomeIcon className="products-icon" icon={faSearchPlus} />
                    </div>
                    
                </div>
            </div>
            <PopupboxContainer {...popupboxConfigProduct001} />
            <PopupboxContainer {...popupboxConfigProduct002} />
            <PopupboxContainer {...popupboxConfigProduct003} />
            <PopupboxContainer {...popupboxConfigProduct004} />
        </div>
    )
}

export default Products

// {/* - */}
// <div className="products-image-box" onClick={openPopupboxProduct003}>
// <img className="products-image" src={product_003} alt="Product 003"></img>
// <div className="overflow"></div>
// <FontAwesomeIcon className="products-icon" icon={faSearchPlus} />
// </div>
// {/* - */}
// <div className="products-image-box" onClick={openPopupboxProduct004}>
// <img className="products-image" src={product_004} alt="Product 004"></img>
// <div className="overflow"></div>
// <FontAwesomeIcon className="products-icon" icon={faSearchPlus} />
// </div>
