import React from 'react';
import aboutImage from '../about.jpg';

const About = () => {
    return (
        <div id="about" className="container py-5">
            <div className="row">
                <div className="col-lg-6 col-xm-12">
                    <div className="photo-wrap mb-5">
                        <img className="about-img" src={aboutImage} alt="about image" />
                    </div>
                </div>
                <div className="col-lg-6 col-xm-12">
                    <h1 className="about-heading">About</h1>
                    <p>
                        At Command Robotics, our mission is to advance robotics through collaborative technological innovation.
                    </p>
                    <p>
                        We see the power of the Internet is enhancing innovations in robotics, advancing industry capabilities and continuing to make robotics more cost effective. Our technology centered around Task Logistics, currently in beta, is designed to enhance the functionality and efficiency of existing robotic platforms.
                    </p>                   
                    <p>
                        Command-Central redefines robotic integration and managing tasks amongst many robots in many environments. This cloud-based platform, leveraging Stellar Blockchain's robust and cost-effective transaction layer, enables seamless fleet management and versatile task handling in complex environments. Designed for robotics companies, it offers an advanced, adaptive logistic system, facilitating Robotics-as-a-Service and pushing the boundaries of collaborative technological innovation in robotics.
                    </p>
                    <p>
                        Are you looking to give your robotic platforms a competitive edge? Command Robotics specializes in advanced integration solutions that can propel your technology forward. We invite robotics companies and technology enthusiasts to join us in shaping the future of robotics. Contact us for a strategic consultation and learn how our innovation can transform your capabilities.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About
