import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import avatar1 from '../images/avatars/avatar-1.png';
import avatar2 from '../images/avatars/avatar-2.png';
import avatar3 from '../images/avatars/avatar-3.png';
import avatar4 from '../images/avatars/avatar-4.png';

const CustomersCarousel = () => {
    return (
        <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={8000}
        >
            <>
                <img src={avatar3} alt="Manufacturing professional" />
                <div className="myCarousel">
                    <h3>Manufacturing professionals</h3>
                    <p>
                        The manufacturing market is a large user of robotics, increasing productivity and scale manufacturing operations.
                        It has never been more important for owners and operators to continue innovate and to leverage technology.
                    </p>
                </div>
            </>
            <>
                <img src={avatar2} alt="Medical professional" />
                <div className="myCarousel">
                    <h3>Medical professionals</h3>
                    <p>
                        Medical robots assist with cleaning, streamline hospital logistics, and enable providers to give more direct attention to patients.
                        Robots in the medical field are transforming how patients are cared for, streamlining supply delivery and disinfection, and freeing
                        up time for providers to engage with patients.
                    </p>
                </div>
            </>
            <>
                <img src={avatar1} alt="Technology Developers" />
                <div className="myCarousel">
                    <h3>Technology Developers</h3>
                    <p>
                        Building robots is a complex and knowledge driven engineering feat.  Professionals building robotics are looking for
                        new and creative ways to bring their robots to market.  Through Command-Central, robotic integrators and developers are incentivized to keep their robots running and performing as many jobs as possible.
                    </p>
                </div>
            </>

        </Carousel>
    )
}

export default CustomersCarousel

    // <>
    //             <img src={avatar4} alt="John Doe 4" />
    //             <div className="myCarousel">
    //                 <h3>John Doe 4</h3>
    //                 <p>
    //                     Customer 4 Lorem ipsum dolor sit amet, consectetur adipisicing elit.
    //                     Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit
    //                     optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?
    //                 </p>
    //             </div>
    //         </>