import React from 'react';

const Roadmap = () => {
    return (
        <div id="roadmap" className="roadmap">
            <div className="d-flex justify-content-center my-5">
                <h1>roadmap</h1>
            </div>
            <div className="container roadmap-wrapper">
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>January 2021</h3>
                        <p>
                            First deployment of the goal management algorithm into a cloud environment. 
                        </p>
                    </div>
                </div>
                {/* ---- */}
                <div className="timeline-block timeline-block-left">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>August 2021</h3>
                        <p>
                            Finalized development and testing with full manufacturing simulation and home service robot.  Started researching strategies for offering robots as a service.
                        </p>
                    </div>
                </div>
                {/* ---- */}
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>December 2021</h3>
                        <p>
                            Completed blockchain integration architecture and was selected by the Stellar Community Fund                             
                            as a winner of their <a className="hyper-link" onClick={() => window.open("https://medium.com/stellar-community/announcing-the-winners-of-scf-9-db1e0c770e54", "_blank")}> 9th round of community funding</a>.
                        </p>
                    </div>
                </div>
                {/* ---- */}
                <div className="timeline-block timeline-block-left">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>January 2022</h3>
                        <p>
                            Successfully completed full testing in the <a className="hyper-link" onClick={() => window.open("https://medium.com/@michael.muldoon.home/command-robotics-testing-in-stellar-testnet-environment-36c37d333807", "_blank")}> Stellar TestNet environment</a>.
                        </p>
                    </div>
                </div>
                {/* ---- */}
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>February 2022</h3>
                        <p>
                            Successfully completed move to <a className="hyper-link" onClick={() => window.open("https://medium.com/@michael.muldoon.home/command-robotics-testing-in-stellar-public-environment-9e7e15450a39", "_blank")}> Stellar Public environment</a> and moved from Stellar's native asset Lumens to Circle's stablecoin USDC.
                        </p>
                    </div>
                </div>
                {/* ---- */}
                <div className="timeline-block timeline-block-left">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>Spring 2023</h3>
                        <p>
                            Demonstrated with commercially available robotic platform, <a className="hyper-link" onClick={() => window.open("https://www.linkedin.com/posts/frank-naccarato-b60a797_robots-robotics-cobots-activity-7096838394940440576-KCqb?utm_source=share&utm_medium=member_desktop", "_blank")}> QBii by Quantum Robotics Systems.</a>            
                        </p>
                    </div>
                </div>
                <div className="timeline-block timeline-block-right">
                    <div className="marker"></div>
                    <div className="timeline-content">
                        <h3>2024</h3>
                        <p>
                            Upgrade robotics test bed to ROS2 and look for collaboration in the drone space.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap;
