import React, { useState, useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';

const Contact = () => {

    return (
        <div id="contact" className="contact">

            <div className="text-center">
                <h1>contact us</h1>
                <p>We want to hear from you.</p> 
                <p>Sumbit your contact information to keep up to date and to learn more about our work!</p>
            </div>

            <div className="container">
                <HubspotForm
                    portalId='21316089'
                    formId='507e8baa-2950-4d79-b089-506c9bd9593e'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<div>Loading...</div>}
                />
            </div>
        </div>
    )
}

export default Contact;