import React from 'react';
import CustomersCarousel from './CustomersCarousel';

const Customers = () => {
    return (
        <div id="customers" className="customers">
            <h1>Customers</h1>
            <div className="container">
                <div className="customers-content">
                    <CustomersCarousel />
                </div>
            </div>
        </div>
    )
}

export default Customers;
