import React from 'react'
import logo from '../logo.png';
import { Link } from 'react-scroll';

//REACT font awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
            <div class="container">

                <a className="navbar-brand" href="#"><img className="logo" src={logo} alt="logo" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon icon={faBars} style={{ color: "#1070fc" }} />
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item active">
                            <Link smooth={true} to="home" offset={0} className="nav-link" href="#">Home <span className="sr-only">(current)</span> </Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="about" offset={-50} className="nav-link" href="#">About</Link>
                        </li>
                        <li className="nav-item active">
                            <Link smooth={true} to="products" offset={0} className="nav-link" href="#">Products</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="customers" offset={-50} className="nav-link" href="#">Customers</Link>
                        </li>
                        <li className="nav-item active">
                            <Link smooth={true} to="roadmap" offset={-25} className="nav-link" href="#">Road Map</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="contact" offset={0} className="nav-link" href="#">Contact us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar


    // < li className = "nav-item" >
    //     <Link smooth={true} to="services" offset={-50} className="nav-link" href="#">Services</Link>
    //  </li >   